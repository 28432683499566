import { useEffect, useState } from 'react';
import useViewportSize from './useViewportSize';
export default function useElementIntersection(element) {
  const {
    0: isIntersecting,
    1: setIntersecting
  } = useState(false);
  const viewportSize = useViewportSize(); // Check for visibility of element

  useEffect(() => {
    if (false || !element) return;
    const availableHeight = window.innerHeight;
    const elementHeight = element && element.offsetHeight || 0; // calculate threesold based
    // on target element height
    // and window height

    const elementTallerThanViewport = availableHeight < elementHeight;
    let threshold = 0.5;

    if (elementTallerThanViewport) {
      threshold = elementHeight / availableHeight / 2;
    }

    if (element) {
      const observer = new IntersectionObserver(_ref => {
        let [entry] = _ref;
        setIntersecting(entry.isIntersecting);
      }, {
        threshold: Math.min(1, Math.max(0, threshold))
      });
      observer.observe(element); // Remove the observer on component unmount

      return () => observer.disconnect();
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [element, viewportSize.height]);
  return isIntersecting;
}