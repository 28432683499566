import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["event"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

const PREFIX = 'common: utils: dom:';
const SUPPORTS_SMOOTH_SCROLL = typeof document !== 'undefined' && 'scrollBehavior' in document.documentElement.style; // Source: https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-change-or-input-event-in-react-js-from-jquery-or#answer-71340077

export function triggerNativeEvent(elm, _ref) {
  var _desc$set;

  let {
    event
  } = _ref,
      valueObj = _objectWithoutProperties(_ref, _excluded);

  const [prop, value] = Object.entries(valueObj)[0] ?? []; // @ts-ignore

  const desc = Object.getOwnPropertyDescriptor(elm.__proto__, prop);
  desc === null || desc === void 0 ? void 0 : (_desc$set = desc.set) === null || _desc$set === void 0 ? void 0 : _desc$set.call(elm, value);
  elm.dispatchEvent(new Event(event, {
    bubbles: true
  }));
} // Create valid hash of inline CSS styles

export function toStylesAttribute(obj) {
  const result = {};
  Object.keys(obj).forEach(key => {
    const typeOf = typeof obj[key];

    if (typeOf === 'string' || typeOf === 'number') {
      result[key] = obj[key];
    }
  });
  return result;
} // Get the DOM element at a specified coordinate

export const elementFromPoint = (x, y) => document.elementFromPoint(x, y); // Determine if element is visible within viewport
// Based on answer: https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport

export function isElementVisible(el) {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight; // Return false if it's not in the viewport

  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false; // Return true if any of its four corners are visible

  return el.contains(elementFromPoint(rect.left, rect.top)) || el.contains(elementFromPoint(rect.right, rect.top)) || el.contains(elementFromPoint(rect.right, rect.bottom)) || el.contains(elementFromPoint(rect.left, rect.bottom));
} // Smooth scroll or fallback to old jump to scroll

export function smoothScrollTo(scrollOptions, targetEl) {
  if (typeof scrollOptions.top !== 'number' && typeof scrollOptions.left !== 'number') {
    throw Error(`${PREFIX} smoothScrollTo: requires top or left numeric value`);
  }

  const scrollingEl = targetEl || document.scrollingElement || document.documentElement;

  if (SUPPORTS_SMOOTH_SCROLL) {
    scrollingEl.scroll(_objectSpread({
      behavior: 'smooth'
    }, scrollOptions));
  } else {
    scrollingEl.scrollTo(scrollOptions);
  }
} /////////////////////////////////////////////////////////////////////////
// Based on code from npm package scrollparent
// https://github.com/olahol/scrollparent.js/blob/master/scrollparent.js
/////////////////////////////////////////////////////////////////////////

const validOverflowRegExp = /(auto|scroll)/;

function getParents(node, ps) {
  if (node.parentNode === null) {
    return ps;
  }

  return getParents(node.parentNode, ps.concat([node]));
}

function getStyleValue(node, prop) {
  return getComputedStyle(node, null).getPropertyValue(prop);
}

function getOverflowStyles(node) {
  return getStyleValue(node, 'overflow') + getStyleValue(node, 'overflow-y') + getStyleValue(node, 'overflow-x');
}

function testElementOverflows(node) {
  return validOverflowRegExp.test(getOverflowStyles(node));
} // Lookup element's first scrollable parent


export function getScrollParent(node) {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return null;
  }

  const ps = getParents(node.parentNode, []);

  for (let i = 0; i < ps.length; i += 1) {
    if (testElementOverflows(ps[i])) {
      return ps[i];
    }
  }

  return null;
}