import { useEffect, useState } from 'react';
import resizeStream from '@/utils/resizeStream';
export default function useViewportSize() {
  const {
    0: viewportSize,
    1: setViewportSize
  } = useState({
    width: 0,
    height: 0
  }); // Set window size on load & resize

  useEffect(() => {
    const subscription = resizeStream.subscribe({
      next: setViewportSize
    }); // Initial canvas sizing

    if (true) {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    } // Unscubscribe resize on exit


    return () => subscription.unsubscribe(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return viewportSize;
}